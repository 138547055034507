import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

// markup
const NotFoundPage = () => (
  <main id="main">
    {/* <SubHeaderGeneric productName={tempProductName} /> */}
    <div className="grid-content-footer px-medium pt-small fs-mm-xl">
      <article className="grid-child-content pb-large pb-mm-xl max-width-800">
        {/* <h1 className="c-em mb-small">Contact us</h1> */}
        <h1 className="fw-medium pb-medium">Page not found</h1>
        <p>Sorry, you’ve been linked to a page that does not exist.</p>
        <p>
          Please do drop us a line at{" "}
          <a href="mailto:general@ratio.lighting">general@ratio.lighting</a>
        </p>
      </article>
    </div>
  </main>
);

export default NotFoundPage;
